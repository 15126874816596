.card_style {
  background-image: url('../../public/media/illustrations/sketchy-1/4.png') !important;
  background-size: auto calc(100% + 10rem);
  background-position-x: 100%;
}
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-input__label {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  font-size: 14px;
  padding: 8px 17px;
  background-color: #009ef7;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.25);
}

.file-input__label svg {
  height: 16px;
  margin-right: 4px;
}

.file-text-main {
  margin-left: 20px;
}
.margin {
  margin-left: 20px;
}

.file-btn {
  margin-left: 14px;
}
.file-text-main {
  margin-left: 20px;
}
.margin {
  margin-left: 20px;
}
.menu-item .menu-link .menu-arrow {
  display: none !important;
}
.dataSource-crete-btn {
  background-color: #009ef7 !important;
  color: white !important;
}
.openeyeIcon {
  width: 18px;
  height: 18px;
}
.ActionIcon {
  margin-left: 8px;
}

.databaseIcon {
  color: #009ef7 !important;
  width: 25px;
  height: 25px;
}
.upload-folder-item {
  margin-right: 28px !important;
}
.upload-folder-table {
  margin-left: 28px !important;
  margin-right: 28px !important;
}
.dataSource-item {
  margin-right: 28px;
}
.dataSource-table {
  margin-left: 28px;
  margin-right: 28px;
}
.upload-file-btn {
  margin-left: 30px;
  background-color: #009ef7 !important;
  color: white !important;
  height: 38px !important;
  font-size: 600 !important;
}
.library-text-modal {
  width: 150px !important;
}
.library-name-modal {
  margin-top: -30px !important;
}
.library-search-modal {
  margin-top: 10px !important;
}
.ScrollStyle {
  max-height: 300px;
  overflow-y: scroll;
}
.ScrollStyle {
  max-height: 300px;
  overflow-y: scroll;
}

.ScrollStyle {
  max-height: 300px;
  overflow-y: scroll;
}
.fileManage-upload-file {
  font-weight: 800 !important;
}
.create-action-type-form {
  width: 100% !important;
  height: 200px !important;
}
.create-promt-modal {
  margin-top: -25px !important;
}
.dataSource-second-modal {
  margin-top: -20px !important;
}
.dataSource-backbtn {
  background-color: #009ef7 !important;
  color: white !important;
  margin-top: 250px !important;
}
.library-icon {
  margin-right: 10px !important;
}

#top-menu li.active {
  /*border-left: 4px solid;*/
  color: #e54e1b;
}

#top-menu li.active p {
  color: #e54e1b;
  font-weight: 600;
}

#top-menu p {
  font-size: 14px;
}
#items-menu {
  overflow-x: hidden;
  overflow-y: auto;
}

#items-menu::-webkit-scrollbar {
  display: none;
}

#items-menu {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#top-menu li.active {
  border-bottom: 4px solid #e54e1b;
  border-left: 0;
}

#items-menu {
  overflow-y: hidden;
  overflow-x: auto;
  top: 70px;
}

.orderItem-removeOverlay {
  height: calc(var(--vh, 1vh) * 50);
}

.nav-justified {
  width: 100%;
}

.tableFixHead {
  overflow: auto;
  height: 50vh;
}
.tableFixHead thead th {
  position: sticky;
  top: -17px;
  z-index: 1;
  background: white;
}

.cursor-pointer {
  cursor: pointer;
}

.replyOnStep {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

.Drawer {
  width: 100%;
  transform: translateY(100%);
  transition: transform ease-out 0.3s;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1;
}

.Drawer.is-open {
  transform: translateY(0);
}
.btn-facebook {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #3b5998;
  border-color: rgba(0, 0, 0, 0.2);
}

.btn-facebook i {
  margin-right: 10px;
}

.btn-facebook p {
  margin: 0;
}
.btn-google1 {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #e8803b;
  border-color: rgba(0, 0, 0, 0.2);
}
.btn-google1 i {
  margin-right: 10px;
}

.btn-google1 p {
  margin: 0;
}
.btn-google1:hover {
  background-color: #cf651e;
  color: #fff;
}

.loginBtn {
  box-sizing: border-box;
  position: relative;
  /* width: 13em;  - apply for fixed size */
  margin: 0.2em;
  padding: 0 15px 0 46px;
  border: none;
  text-align: left;
  line-height: 34px;
  white-space: nowrap;
  border-radius: 0.2em;
  font-size: 16px;
  color: #fff;
}
.loginBtn:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 34px;
  height: 100%;
}
.loginBtn:focus {
  outline: none;
}
.loginBtn:active {
  box-shadow: inset 0 0 0 32px rgba(0, 0, 0, 0.1);
}

/* Facebook */
.loginBtn--facebook {
  background-color: #4c69ba;
  background-image: linear-gradient(#4c69ba, #3b55a0);
  /*font-family: "Helvetica neue", Helvetica Neue, Helvetica, Arial, sans-serif;*/
  text-shadow: 0 -1px 0 #354c8c;
}
.loginBtn--facebook:before {
  border-right: #364e92 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_facebook.png') 6px 6px
    no-repeat;
}
.loginBtn--facebook:hover,
.loginBtn--facebook:focus {
  background-color: #5b7bd5;
  background-image: linear-gradient(#5b7bd5, #4864b1);
}

/* Google */
.loginBtn--google {
  /*font-family: "Roboto", Roboto, arial, sans-serif;*/
  background: #dd4b39;
}
.loginBtn--google:before {
  border-right: #bb3f30 1px solid;
  background: url('https://s3-us-west-2.amazonaws.com/s.cdpn.io/14082/icon_google.png') 6px 6px
    no-repeat;
}
.loginBtn--google:hover,
.loginBtn--google:focus {
  background: #e74b37;
}
